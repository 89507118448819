<template>
  <div class="promo-block" id="tarifs">
    <div class="promo-block__description">
      <h3
        class="page-title promo-block__title"
        v-html="$t('main.promo.title')"
      />
      <h4
        class="top-block__note promo-block__subtitle"
        v-html="$t('main.promo.subtitle')"
      />
    </div>

    <div class="promo-block__info">
      <div class="promo-block__text" v-html="$t('main.promo.text')" />
      <div class="promo-block__note" v-html="$t('main.promo.note')" />

      <a
        href="https://t.me/avoVPN_bot"
        target="_blank"
        rel="noopener noreferrer"
        class="btn promo-block__btn"
      >
        <svg
          width="38"
          height="42"
          viewBox="0 0 38 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="btn__icon"
        >
          <path
            d="M9.97002 38.586C11.347 39.2234 12.6703 40.0108 14.1041 40.4764C24.4629 43.8602 33.7353 38.5829 36.0408 27.8377C36.8461 24.0851 37.2756 20.2201 37.5062 16.3831C37.9988 8.15001 32.2604 3.10702 26.0862 0.985469C23.3733 0.0543597 20.5373 0.529289 18.096 1.99782C13.0556 5.03798 8.2046 8.39685 4.43689 12.943C-3.20274 22.1604 -0.733047 33.2243 9.97002 38.586ZM5.84544 15.1208C9.31627 10.9433 13.7946 7.9063 18.2413 4.888C21.0805 2.96017 24.1597 2.49462 27.3432 4.34746C30.5329 6.20343 31.5119 9.10612 31.2877 12.4962C30.9972 16.8987 30.3908 21.2918 29.2033 25.5318C26.4715 35.3022 17.2433 39.6234 9.24995 35.0616C2.04931 30.9466 0.429162 21.6417 5.84544 15.1208Z"
            fill="currentColor"
          />
          <path
            d="M9.08885 19.2765C6.92234 22.9853 8.11929 27.644 11.8017 29.8562C15.5852 32.1277 20.4583 30.9373 22.7259 27.1878C25.0155 23.3978 23.7839 18.6797 19.9214 16.4363C16.04 14.1867 11.3438 15.4177 9.08885 19.2765Z"
            fill="currentColor"
          />
        </svg>
        <span class="btn__text">
          {{ $t('main.btn') }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromoBlock'
}
</script>
